




















































































// @ts-ignore
import { ArrowNarrowRightIcon } from '@vue-hero-icons/solid'
import { DateTime } from 'luxon'
import { Vue, Component } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import { IConference, IEvent } from '~/types'
import { eventType } from '~/utils/getEvents'

@Component({
  components: { ArrowNarrowRightIcon },
  mixins: [
    {
      methods: { eventType }
    }
  ],
  computed: {
    ...mapGetters('global', ['upcomingEvents', 'pastEvents'])
  }
})
export default class Index extends Vue {
  upcomingEvents!: (IEvent | IConference)[]
  pastEvents!: (IEvent | IConference)[]

  searchInput = ''
  events: (IEvent | IConference)[] = []

  headerImageURL (event: IEvent | IConference): string {
    if (event.header_image[0] === undefined) { return '' }

    switch (event.header_image[0].__component) {
      case 'events.strapi-image': {
        const image = event.header_image[0].image?.formats?.medium || event.header_image[0].image
        if (image === null || image === undefined) { return '' }
        return `${this.$config.assetsBaseUrl}/${encodeURIComponent(image.hash)}${encodeURIComponent(image.ext)}`
      }
      case 'events.unsplash-image': {
        return event.header_image[0].url
      }
      default:
        return ''
    }
  }

  formatDate (event: IEvent | IConference): string {
    let date
    if (eventType(event) === 'conference') {
      date = DateTime.fromISO(event.start_time)
    } else {
      date = DateTime.fromISO(`${(event as IEvent).date}T${event.start_time}`)
    }
    return date.toLocaleString(DateTime.DATE_FULL)
  }

  search () {
    this.events = this.upcomingEvents.slice(0, 5)
  }
}
